/* External dependencies */
import { Button, Col, Div, Image, Row, Text } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Local dependencies
import { createPrizeUpdateFields } from '@entities/prizes/redux/createPrize/actions';
import EditPencilIcon from '@svg/EditPencilIcon';
import DeleteTrashCanIcon from '@svg/DeleteTrashCanIcon';
import UploadImageIcon from '@svg/UploadImageIcon';
import { errorMessageStyle } from '@shared/ui/form/FormStyles';
import './style.scss';

interface ImageUploaderProps {
  name: string;
  value: any;
  error: Error;
}

export default function ImageUploader({
  name,
  value,
  error,
}: ImageUploaderProps) {
  const inputFileRef = useRef();
  const [isHovering, setIsHovering] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(
    value[0]?.url || null,
  );
  const dispatch = useDispatch();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = e.target?.result as string;
        setSelectedPhoto(base64Data);
        dispatch(createPrizeUpdateFields({ [name]: base64Data }));
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  function onDeleteImage() {
    dispatch(createPrizeUpdateFields({ [name]: '' }));
    setSelectedPhoto(null);
  }

  function onChangeImage() {
    inputFileRef?.current?.click();
  }

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Div
      p={{ y: '16px' }}
      d="flex"
      justify="center"
      align="center"
      flexDir="column"
    >
      <Div
        w="230px"
        h="230px"
        bg="#F4F6FB"
        pos="relative"
        className="create-prize__wrapper"
        d="flex"
        justify="center"
        align="center"
        flexDir="column"
      >
        {selectedPhoto ? (
          <>
            <Image
              src={selectedPhoto}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                objectFit: 'contain',
              }}
              alt="prize"
            />
            <Div className="create-prize__overlay"></Div>
            <Div className="create-prize__hover-buttons">
              <Button
                rounded="circle"
                h="50px"
                w="50px"
                bg="#00000099"
                hoverBg="primary"
                onClick={onChangeImage}
                className="create-prize__hover-buttons__edit"
                cursor="pointer"
              >
                <EditPencilIcon width={16} height={18} />
              </Button>
              <Button
                rounded="circle"
                h="50px"
                w="50px"
                bg="#00000099"
                hoverBg="primary"
                onClick={onDeleteImage}
                className="create-prize__hover-buttons__delete"
                cursor="pointer"
              >
                <DeleteTrashCanIcon width={20} height={20} />
              </Button>
            </Div>
          </>
        ) : (
          <Div
            cursor="pointer"
            className="create-prize__image-uploader"
            onClick={onChangeImage}
          >
            <Div
              d="flex"
              flexDir="column"
              justify="center"
              align="center"
              style={{ gap: '1rem' }}
              p={{ y: '1rem' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <UploadImageIcon fill={isHovering ? '#222b45' : '#6574CF'} />
              <Text
                textTransform="uppercase"
                textWeight="700"
                textColor={isHovering ? 'black' : 'primary'}
                textSize="12px"
              >
                <Trans>uploadImage</Trans>
              </Text>
            </Div>
          </Div>
        )}
        <label className="file-input" style={{ width: '100%' }}>
          <input
            type="file"
            ref={inputFileRef}
            onChange={handleFileChange}
            accept="image/*"
            style={{ opacity: '0', width: '100%' }}
            name={name}
          />
        </label>
      </Div>
      <Text textSize="caption" {...errorMessageStyle} h="16px">
        <Trans>{error && error?.code}</Trans>
      </Text>
    </Div>
  );
}
