// External dependensies
import { Div, Text } from 'atomize';
import { navigate } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependensies
import { RootState } from '@app//redux/store';
import getPrizesFields from '@entities/prizes/lib/helper';
import { Prize } from '@entities/prizes/lib/types';
import { listPrizes } from '@entities/prizes/redux/listPrizes/actions';
import ManagerControlBarPrizes from '@feautures/ui/prizes-controller/ManagerControlBarPrizes';
import Header from '@shared/ui/admin/header/Header';
import Table, { RowSelectionType } from '@shared/ui/table/Table';
import { setLocalStorage } from '@shared/utils/helpers';
import { getDateTime } from '@shared/utils/renderDate';
import { UserRoles, getUserRole } from '@shared/utils/roles';
import DefaultAvatarIcon from '@svg/DefaultAvatarIcon';
import CreatePrize from '../createPrize';
import {
  createPrizeFormOpenClose,
  createPrizeOpenCloseConfirmationPopup,
  createPrizeReset,
} from '@entities/prizes/redux/createPrize/actions';
import PrimaryPopup from '@shared/ui/popup/PrimaryPopup';

export default function ListPrizes() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { currentPage, loading, prizes, searchString, total } = useSelector(
    (state: RootState) => state.listPrizes,
  );
  const {
    isCreatePrizeFormOpen,
    isConfirmationPopupOpen,
    loading: createPrizeLoading,
  } = useSelector((state: RootState) => state.createPrize);

  const { t } = useI18next();

  function getImage(prize: Prize) {
    return (
      <>
        {prize?.avatar.length > 0 ? (
          <img
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            src={prize?.avatar[0].url}
            alt={prize?.name}
          />
        ) : (
          <DefaultAvatarIcon />
        )}
      </>
    );
  }

  function onPageChange(page: number) {
    dispatch(listPrizes(searchString, page));
  }

  function closeCreatePrizeForm() {
    dispatch(createPrizeReset());
    dispatch(createPrizeFormOpenClose(false));
  }

  return (
    <Div m="0 auto" className="container">
      <Header />
      <Div p={{ t: '80px' }}>
        <Table
          items={prizes?.map((prize: Prize) => ({
            id: prize?.id,
            photo: getImage(prize),
            name: prize?.name,
            requiredPoints: `${prize?.requiredPoints}`,
            postDate: getDateTime(prize?.postDate),
            orderNumber: `${prize?.orderNumber}`,
            status: prize?.status,
          }))}
          onClickItem={null}
          columns={getPrizesFields()}
          currentPage={currentPage}
          emptyMessage="noPrizes"
          controlBar={getControlBar()}
          loading={loading}
          onPageChange={onPageChange}
          stickyCol={1}
          searchString={''}
          selectionType={RowSelectionType.NUMBER}
          topBar={''}
          totalItemsCount={total}
        />
      </Div>

      {isCreatePrizeFormOpen && <CreatePrize onClose={closeCreatePrizeForm} />}
    </Div>
  );

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (true) {
      case userRole.includes(UserRoles.MANAGER): {
        return <ManagerControlBarPrizes />;
      }

      case userRole.includes(UserRoles.MEDUCATION_MANAGER): {
        return <ManagerControlBarPrizes />;
      }

      default:
        return null;
    }
  }
}
