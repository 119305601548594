/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function UploadImageIcon({
  width = 24,
  height = 26,
  fill = '#6574CF',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.799988 24.2C0.799988 23.3163 1.51633 22.6 2.39999 22.6H21.6C22.4836 22.6 23.2 23.3163 23.2 24.2C23.2 25.0836 22.4836 25.8 21.6 25.8H2.39999C1.51633 25.8 0.799988 25.0836 0.799988 24.2ZM6.06862 7.73132C5.44378 7.10648 5.44378 6.09342 6.06862 5.46858L10.8686 0.668581C11.1687 0.368523 11.5756 0.199951 12 0.199951C12.4243 0.199951 12.8313 0.368523 13.1314 0.668581L17.9314 5.46858C18.5562 6.09342 18.5562 7.10648 17.9314 7.73132C17.3065 8.35616 16.2935 8.35616 15.6686 7.73132L13.6 5.66269V17.8C13.6 18.6836 12.8836 19.4 12 19.4C11.1163 19.4 10.4 18.6836 10.4 17.8L10.4 5.66269L8.33136 7.73132C7.70652 8.35616 6.69346 8.35616 6.06862 7.73132Z"
        fill={fill}
      />
    </svg>
  );
}
