export interface ListPrizesInput {
  from?: number;
  size?: number;
  sort?: ListPrizeSort;
  locale?: string;
}

export interface ListPrizeSort {
  field: string;
}

export interface PrizesListResponse {
  total: number;
  prizes: Prize[];
}

export interface Prize {
  id: string;
  name: string;
  avatar?: Avatar[];
  status?: Status;
  requiredPoints: number;
  postDate?: string;
  orderNumber?: number;
}

export interface Avatar {
  url: string;
}

export enum Status {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
