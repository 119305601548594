// External dependecies
import React from 'react';
import { Trans } from 'react-i18next';

// Local dependencies
import { Status } from './types';

export default function getPrizesFields() {
  return [
    {
      field: 'id',
      title: <Trans>ID</Trans>,
    },
    {
      field: 'photo',
      title: <Trans>Photo</Trans>,
    },
    {
      field: 'name',
      title: <Trans>firstName</Trans>,
    },
    {
      field: 'requiredPoints',
      title: <Trans>requiredPoints</Trans>,
    },

    {
      field: 'postDate',
      title: <Trans>joinDate</Trans>,
    },
    {
      field: 'orderNumber',
      title: <Trans>orderNumber</Trans>,
    },
    {
      field: 'status',
      title: <Trans>status</Trans>,
    },
  ];
}

export const prizesFilters = [
  {
    label: 'ALL',
    value: JSON.stringify('ALL'),
  },
  {
    label: Status.ENABLED,
    value: JSON.stringify(Status.ENABLED),
  },
  {
    label: Status.DISABLED,
    value: JSON.stringify(Status.DISABLED),
  },
];
