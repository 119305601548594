/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function DeleteTrashCanIcon({
  width = 14,
  height = 14,
  fill = 'white',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66668 9.66658C9.66668 10.0333 9.36668 10.3333 9.00001 10.3333C8.63334 10.3333 8.33334 10.0333 8.33334 9.66658V6.99992C8.33334 6.63325 8.63334 6.33325 9.00001 6.33325C9.36668 6.33325 9.66668 6.63325 9.66668 6.99992V9.66658ZM5.66668 1.88525C5.66668 1.78192 5.80934 1.66659 6.00001 1.66659H8.00001C8.19068 1.66659 8.33334 1.78192 8.33334 1.88525V2.99992H5.66668V1.88525ZM5.66668 9.66658C5.66668 10.0333 5.36668 10.3333 5.00001 10.3333C4.63334 10.3333 4.33334 10.0333 4.33334 9.66658V6.99992C4.33334 6.63325 4.63334 6.33325 5.00001 6.33325C5.36668 6.33325 5.66668 6.63325 5.66668 6.99992V9.66658ZM13 2.99992H12.3333H9.66668V1.88525C9.66668 1.02925 8.91934 0.333252 8.00001 0.333252H6.00001C5.08068 0.333252 4.33334 1.02925 4.33334 1.88525V2.99992H1.66668H1.00001C0.633343 2.99992 0.333344 3.29992 0.333344 3.66658C0.333344 4.03325 0.633343 4.33325 1.00001 4.33325H1.66668V11.6666C1.66668 12.7693 2.56401 13.6666 3.66668 13.6666H10.3333C11.436 13.6666 12.3333 12.7693 12.3333 11.6666V4.33325H13C13.3667 4.33325 13.6667 4.03325 13.6667 3.66658C13.6667 3.29992 13.3667 2.99992 13 2.99992Z"
        fill={fill}
      />
    </svg>
  );
}
