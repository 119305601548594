/* External dependencies */
import { Button as AtomizeButton, Col, Div, Row, Text } from 'atomize';
import React from 'react';
import { Trans } from 'react-i18next';

// Local dependencies
import {
  createPrizeOpenCloseConfirmationPopup,
  createPrizeUpdateFields,
} from '@entities/prizes/redux/createPrize/actions';
import MainPopup from '@shared/ui/popup/MainPopup';
import { RootState } from '@app/redux/store';
import Button from '@shared/ui/button';
import FormField from '@shared/ui/form/FormField';
import { errorMessageStyle } from '@shared/ui/form/FormStyles';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploader from './ImageUploader';
import './style.scss';
import PrimaryPopup from '@shared/ui/popup/PrimaryPopup';

interface CreatePrizeProps {
  onClose: () => void;
}

export default function CreatePrize(props: CreatePrizeProps) {
  const { onClose } = props;

  const {
    prize,
    nameError,
    avatarError,
    requiredPointsError,
    orderNumberError,
    amountError,
    isConfirmationPopupOpen,
    loading,
  } = useSelector((state: RootState) => state.createPrize);
  const { name, avatar, requiredPoints, orderNumber, amount } = prize;

  const dispatch = useDispatch();

  const isNotValid =
    avatarError ||
    nameError ||
    requiredPointsError ||
    amountError ||
    !name ||
    !requiredPoints ||
    !amount ||
    !avatar[0].url;

  const fields = [
    { key: 'name', value: name },
    { key: 'requiredPoints', value: requiredPoints },
    { key: 'amount', value: amount },
    { key: 'avatar', value: avatar[0]?.url },
  ];

  const borderTopBottomStyle = {
    borderTop: '1px solid #EDF1F7',
    borderBottom: '1px solid #EDF1F7',
  };

  function onInputChange(e) {
    const { value, name } = e.target;

    dispatch(createPrizeUpdateFields({ [name]: value }));
  }

  function onSubmit() {
    const allowContinue = validateFields();

    if (allowContinue) {
      openConfirmationPopup();
    }
  }

  function validateFields() {
    fields.forEach((field) => {
      const { key, value } = field;
      if (!value) {
        dispatch(createPrizeUpdateFields({ [key]: value }));
      }
    });

    return isNotValid ? false : true;
  }

  function openConfirmationPopup() {
    dispatch(createPrizeOpenCloseConfirmationPopup(true));
  }

  function closeConfirmationPopup() {
    dispatch(createPrizeOpenCloseConfirmationPopup(false));
  }

  function handleCreatePrize() {}

  return (
    <MainPopup
      title={
        <Text textSize="20px" textWeight="700">
          <Trans>loadingAPrize</Trans>
        </Text>
      }
      onClose={onClose}
      maxH="400"
    >
      <Div
        style={borderTopBottomStyle}
        p={{ x: '16px' }}
        className="create-prize"
      >
        <ImageUploader name="avatar" error={avatarError} value={avatar} />

        <Div p={{ y: '1rem', b: '2rem' }}>
          <Row style={{ whiteSpace: 'nowrap' }}>
            <FormField
              bg="basic_border_color"
              inputType="text"
              label={<Trans>namePrize</Trans>}
              inputName="name"
              inputValue={name}
              onChange={onInputChange}
              error={nameError}
            />
          </Row>
          <Row style={{ whiteSpace: 'nowrap' }}>
            <Col size={{ xs: '12', sm: '6' }}>
              <Row>
                <FormField
                  bg="basic_border_color"
                  inputType="number"
                  label={<Trans>price</Trans>}
                  inputName="requiredPoints"
                  inputValue={requiredPoints}
                  onChange={onInputChange}
                  error={requiredPointsError}
                />
              </Row>
            </Col>
            <Col size={{ xs: '12', sm: '6' }}>
              <Row>
                <FormField
                  bg="basic_border_color"
                  inputType="number"
                  label={<Trans>amount</Trans>}
                  inputName="amount"
                  inputValue={amount}
                  onChange={onInputChange}
                  error={amountError}
                />
              </Row>
            </Col>
          </Row>
        </Div>
      </Div>
      <Row d="flex" justify="flex-end" p="16px">
        <Col size={{ xs: '12', sm: '6', md: '4' }}>
          <AtomizeButton
            border="1px solid"
            borderColor="gray"
            bg="white"
            textColor="black"
            hoverBg="gray_bg_hover"
            rounded="4px"
            onClick={onClose}
          >
            <Text textTransform="uppercase" textSize="12px" textWeight="700">
              <Trans>cancel</Trans>
            </Text>
          </AtomizeButton>
        </Col>
        <Col size={{ xs: '12', sm: '6', md: '4' }}>
          <Button typeColor="primary" onClick={onSubmit} rounded="4px">
            <Text textTransform="uppercase" textSize="12px" textWeight="700">
              <Trans>save</Trans>
            </Text>
          </Button>
        </Col>
      </Row>
      {isConfirmationPopupOpen && (
        <PrimaryPopup
          title={'createPrize'}
          btnText={'create'}
          cancelBtnText="cancel"
          onClick={handleCreatePrize}
          onClose={closeConfirmationPopup}
          loading={loading}
          btnForActionTypeColor={'success'}
        >
          <Div
            maxW="350px"
            textSize="display1"
            textColor="warning_color"
            textWeight="500"
          >
            <Trans>youWantToCreateAPrize</Trans>
          </Div>
        </PrimaryPopup>
      )}
    </MainPopup>
  );
}
