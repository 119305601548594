/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function EditPencilIcon({
  width = 14,
  height = 14,
  fill = 'white',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67907 5.1192L6.8824 3.32253L8.18107 2.0232L9.97707 3.8192L8.67907 5.1192ZM10.9357 2.89186L10.9351 2.8912L9.10974 1.06586C8.61574 0.573198 7.76707 0.549865 7.29907 1.01986L1.30174 7.0172C1.0844 7.23386 0.949737 7.52186 0.921737 7.82653L0.669071 10.6065C0.651737 10.8032 0.721737 10.9979 0.861737 11.1379C0.987737 11.2639 1.15774 11.3332 1.33307 11.3332C1.35374 11.3332 1.37374 11.3325 1.39374 11.3305L4.17374 11.0779C4.47907 11.0499 4.7664 10.9159 4.98307 10.6992L10.9811 4.7012C11.4664 4.21453 11.4457 3.40253 10.9357 2.89186Z"
        fill={fill}
      />
    </svg>
  );
}
